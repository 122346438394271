@import 'react-toastify/dist/ReactToastify.css';

@font-face {
    font-family: "Roboto-Bold";
    src: url("/fonts/roboto/Roboto-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Roboto-Medium";
    src: url("/fonts/roboto/Roboto-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("/fonts/roboto/Roboto-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto-Light";
    src: url("/fonts/roboto/Roboto-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Slab-Medium";
    src: url("/fonts/roboto-slab/RobotoSlab-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Slab-Regular";
    src: url("/fonts/roboto-slab/RobotoSlab-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Slab-Light";
    src: url("/fonts/roboto-slab/RobotoSlab-Light.ttf");
    font-weight: 300;
}

@keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
}